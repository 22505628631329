// NOTE: Temporary file until we have a shared token library with Mode

export const NEUTRALS = {
  white: '#ffffff',
  black: '#000000',
  'white-a10': '#ffffff1a',
  'white-a20': '#ffffff33',
  'white-a75': '#ffffffbf',
  'gray-99': '#fafafa',
  'gray-95': '#f0f1f3',
  'gray-92': '#e7e8e9',
  'gray-85': '#d9d9d9',
  'gray-75': '#bfbfbf',
  'gray-55': '#8c8c8c',
  'gray-45': '#707177',
  'gray-35': '#515762',
  'gray-16': '#25282d',
  'gray-16-a025': '#25282d06',
  'gray-16-a5': '#25282d0d',
  'gray-16-a10': '#25282d1a',
  'gray-16-a20': '#25282d33',
  'gray-16-a30': '#25282d4d',
  'gray-16-a67': '#25282dab',
  'gray-16-a75': '#25282dbf',
  'gray-16-a85': '#25282dd9',
};

export const MINTS = {
  'mint-95': '#f1f9f7',
  'mint-85': '#c3e4de',
  'mint-70': '#aad9d0',
  'mint-60': '#86c8bc',
  'mint-60-a10': '#86c8bc1a',
  'mint-60-a20': '#86c8bc33',
  'mint-50': '#79b4a9',
  'mint-50-a10': '#79b4a91a',
  'mint-50-a20': '#79b4a933',
  'mint-45': '#5e8c84',
  'mint-40': '#507871',
};

export const LIMES = {
  'lime-95': '#d5fcc6',
  'lime-85': '#6ced9e',
  'lime-70': '#00c380',
  'lime-60': '#00a56c',
  'lime-50': '#008656',
  'lime-45': '#007b4a',
  'lime-40': '#006c45',
};

export const VIOLETS = {
  'violet-95': '#f2efff',
  'violet-85': '#dacdff',
  'violet-70': '#a7a0fe',
  'violet-60': '#8b80fe',
  'violet-50': '#6c5afd',
  'violet-45': '#594dfd',
  'violet-40': '#543deb',
};

export const ORANGES = {
  'orange-95': '#ffede2',
  'orange-85': '#ffc9ae',
  'orange-70': '#ff8b5a',
  'orange-60': '#ea6b00',
  'orange-50': '#c94c00',
  'orange-45': '#c83100',
  'orange-40': '#af3000',
};

export const BLUES = {
  'blue-95': '#f2f4f7',
  'blue-85': '#b2bbcf',
  'blue-70': '#8997b6',
  'blue-60': '#6678a0',
  'blue-50': '#334b80',
  'blue-45': '#001e60',
  'blue-40': '#001b56',
};

export const REDS = {
  'red-95': '#ffeced',
  'red-85': '#ffc6c9',
  'red-70': '#ff888d',
  'red-60': '#ff525e',
  'red-50': '#dd2e3e',
  'red-50-a10': '#dd2e3e1a',
  'red-50-a20': '#dd2e3e33',
  'red-50-a50': '#dd2e3e80',
  'red-45': '#cd243b',
  'red-40': '#bf0029',
};

export const AQUAS = {
  'aqua-95': '#befcff',
  'aqua-85': '#65e5f8',
  'aqua-70': '#00bbda',
  'aqua-60': '#009ebe',
  'aqua-50': '#007ea1',
  'aqua-45': '#007395',
  'aqua-40': '#00677e',
};

export const PINKS = {
  'pink-95': '#ffecf3',
  'pink-85': '#ffc4dc',
  'pink-70': '#ff81bb',
  'pink-60': '#ff43a8',
  'pink-50': '#df008c',
  'pink-45': '#cd0080',
  'pink-40': '#b70072',
};

export const GOLDS = {
  'gold-95': '#fef399',
  'gold-95-a40': '#fef39966',
  'gold-85': '#fdd37e',
  'gold-70': '#e29e00',
  'gold-60': '#c08500',
  'gold-50': '#ad6200',
  'gold-45': '#a75400',
  'gold-40': '#8f4e00',
};

export const ACCENTS = {
  'citrus-50': '#bdcb32',
  'citrus-60': '#d2e137',
  'citrus-70': '#d7e44b',
  'grapefruit-60': '#ff5955',
};

/**
 * Lookup of all the supported Stitch Fix UI color
 * names to their matching hex color value
 */
export const UI = {
  ...MINTS,
  ...LIMES,
  ...VIOLETS,
  ...ORANGES,
  ...BLUES,
  ...REDS,
  ...AQUAS,
  ...PINKS,
  ...GOLDS,
  ...ACCENTS,
};

export const SOCIAL = {
  'afterpay-teal': '#b2fce4',
  'apple-black': '#000',
  'facebook-blue': '#1877f2',
  'google-blue': '#4885ed',
  'imessage-green': '#53d769',
  'linkedin-blue': '#0077b5',
  'messenger-blue': '#007fff',
  'paypal-blue-dark': '#003087',
  'paypal-blue-light': '#009cde',
  'paypal-yellow': '#ffc439',
  'pinterest-red': '#bd081c',
  'twitter-blue': '#1b95e0',
  'venmo-blue': '#3d95ce',
  'whatsapp-teal': '#128c7e',
};

/**
 * Lookup of the semantically named color names to their
 * matching hex color values.
 */
export const SEMANTIC = {};

/**
 * Lookup of all the available color names to their
 * matching hex color value
 */
export const COLORS = {
  ...SEMANTIC,
  ...NEUTRALS,
  ...UI,
  ...SOCIAL,
};

/**
 * Type for all of the available colors that can be used in components
 */
export type Color = keyof typeof COLORS;

export const BRAND_2024_COLORS = COLORS;

/**
 * React hook that returns all available colors (map of name to hex value).
 *
 * Prefer this over the `COLORS` object, because the hook takes into account
 * the currently applied theme (i.e. the 2024 rebrand color changes).
 */
export const useColors = () => {
  return COLORS;
};
