import React, { ReactNode, ElementType } from 'react';
import classNames from 'classnames';
import { PolymorphicComponentProps } from '../_internal/components';
import Box, { BoxProps } from '../Box';
import { MarginProps } from '../_internal/spacing';
import {
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';

import styles from './stickerLabel.module.scss';

export const stickerLabelVariants = [
  'default',
  'inverse',
  'accent',
  'accent-inverse',
  'error',
  'error-light',
  'warn',
  'white',
  'navy',
  'mint',
  'citrus',
] as const;

export type StickerLabelVariant = (typeof stickerLabelVariants)[number];

interface CommonProps extends DeprecatedAndDangerousStylingProps {
  /**
   * The sticker label contents in sentence case
   */
  children: ReactNode;

  /**
   * The sticker label style
   */
  variant?: StickerLabelVariant;
}

type Props = CommonProps & MarginProps;

export type StickerLabelProps<C extends ElementType = 'span'> =
  PolymorphicComponentProps<C, Props>;

/**
 * Sticker labels are very short (one or two word) textual accents used as emphasis (e.g. "New")
 */
const StickerLabel = <C extends ElementType>({
  as,
  children,
  variant = 'default',
  ...rootProps
}: StickerLabelProps<C>) => {
  const boxProps = processStylingProps(rootProps, 'StickerLabel', {
    stylingProps: 'warn',
    dangerousStylingProps: 'rewrite',
  }) as BoxProps<C>;

  const { className: boxPropClassName } = boxProps;
  const className = classNames(
    styles['sticker-label'],
    styles[variant],
    boxPropClassName,
  );

  return (
    <Box as={as || 'span'} {...boxProps} className={className}>
      {children}
    </Box>
  );
};

export default StickerLabel;
