import React, { ReactNode, ElementType, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { PolymorphicComponentProps } from '../_internal/components';
import Box from '../Box';
import Text from '../Text';
import InteractableContainer, {
  InteractableContainerProps,
} from '../InteractableContainer';
import {
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
  ResponsiveValue,
  getResponsiveValue,
} from '../_internal/styling';
import IconCloseX16 from '../icons/IconCloseX16';
import IconCloseX24 from '../icons/IconCloseX24';
import IconError16 from '../icons/IconError16';
import IconSuccess16 from '../icons/IconSuccess16';
import { MarginProps } from '../_internal/spacing';

import styles from './banner.module.scss';
import useScreenSize from '../_internal/useScreenSize';

type BannerVariant =
  | 'citrus'
  | 'error-dark'
  | 'error-border'
  | 'success-border'
  | 'info-light'
  | 'info-dark'
  | 'info-border'
  | 'promo-dark'
  | 'promo-border';

const ERROR_ICON = (
  <IconError16
    className={styles['headline-icon']}
    title="error icon"
    color="inherit"
  />
);

const HEADLINE_ICON_LOOKUP: Record<string, JSX.Element | undefined> = {
  'error-dark': ERROR_ICON,
  'error-border': ERROR_ICON,
  'success-border': (
    <IconSuccess16
      className={styles['headline-icon']}
      title="success icon"
      color="inherit"
    />
  ),
};

interface CommonProps extends DeprecatedAndDangerousStylingProps {
  /**
   * The banner message
   */
  children: ReactNode;
  /**
   * Controls the horizontal alignment.
   * (**DEPRECATED.** Use a `SlimBanner` instead)
   * @default 'left'
   * @deprecated Use a `SlimBanner` instead
   */
  hAlign?: ResponsiveValue<'left' | 'center'>;
  /**
   * The (optional) banner headline
   */
  headline?: ReactNode;
  /**
   * A custom icon override displayed next to the headline. Default icons defined for: `error-border`, `error-dark`, `success-border`.
   */
  icon?: ReactNode;
  /**
   * Callback function for when the close button is clicked.
   * The close button is omitted when this handler is not specified.
   */
  onClose?: () => void;
  /**
   * The main link to click
   */
  primaryLink?: ReactNode;
  /**
   * A secondary link only displayed for in-page banners
   */
  secondaryLink?: ReactNode;
  /**
   * The banner style
   *
   * @default 'info-light'
   */
  variant?: BannerVariant;
}

type Props = CommonProps & MarginProps;

export type BannerProps<C extends ElementType = 'div'> =
  PolymorphicComponentProps<C, Props>;

/**
 * A banner displays an important, succinct message, and provides actions for users to address (or dismiss the banner). It requires a user action to be dismissed.
 * Banners should:
 *
 * - Be used only one at a time
 * - Appear on load or be animated in
 * - Remain until dismissed
 * - Appear inline, not fixed
 */
const Banner = <C extends ElementType>({
  children,
  hAlign: responsiveHAlign = 'left',
  headline,
  icon,
  onClose,
  primaryLink,
  secondaryLink,
  variant = 'info-light',
  ...rootProps
}: BannerProps<C>) => {
  const screenSize = useScreenSize();
  const hAlign = getResponsiveValue(screenSize, responsiveHAlign, 'left');
  const hasLinks = primaryLink || secondaryLink;
  const headlineIcon =
    (icon && <Box className={styles['headline-icon']}>{icon}</Box>) ||
    HEADLINE_ICON_LOOKUP[variant];
  const messageId = `${variant}-message`;
  const headlineId = `${variant}-label`;
  let role = 'status';
  let ariaLive: HTMLAttributes<C>['aria-live'] = 'polite';

  if (variant === 'error-dark' || variant === 'error-border') {
    // `alertdialog` role can only be used when there are associated
    // interactive controls. otherwise use `alert`.
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alertdialog_role
    role = hasLinks ? 'alertdialog' : 'alert';
    ariaLive = 'assertive';
  }

  const containerProps = processStylingProps<
    Partial<InteractableContainerProps>
  >(rootProps, 'Banner', {
    // We are passing these props to InteractableContainer that will processes
    // them again so setting processing mode to avoid duplicate warnings.
    stylingProps: 'makeDangerous',
    dangerousStylingProps: 'keep',
  });

  return (
    <InteractableContainer
      variant={variant}
      role={role}
      aria-live={ariaLive}
      aria-describedby={messageId}
      {...containerProps}
      onClickIcon={onClose}
      icon={{
        sm: <IconCloseX16 />,
        lg: <IconCloseX24 />,
      }}
      iconVAlign={{
        sm: 'top',
        lg: 'middle',
      }}
      iconLabel="close banner"
      iconVariant="inherited"
      {...(headline && { 'aria-labelledby': headlineId })}
    >
      <Box
        className={classNames(styles.content, styles[hAlign], {
          [styles['has-close']]: onClose,
        })}
        // Bring focus to the alertdialog controls.
        {...(role === 'alertdialog' && { role: 'document', tabIndex: 0 })}
        data-testid="content"
      >
        <Box className={styles.copy}>
          {headline && (
            <Text
              id={headlineId}
              height="standard"
              family="medium"
              scale="3"
              className={styles.headline}
            >
              {headlineIcon}
              {headline}{' '}
            </Text>
          )}
          <Text
            as="p"
            id={messageId}
            height="tall"
            family="regular"
            scale="3"
            className={styles.message}
          >
            {children}
          </Text>
        </Box>
        {hasLinks && (
          <Box className={styles.links}>
            {secondaryLink && (
              <Box as="span" className={styles['secondary-link']}>
                {secondaryLink}
              </Box>
            )}
            {primaryLink}
          </Box>
        )}
      </Box>
    </InteractableContainer>
  );
};

export default Banner;
